// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/buddy/spotify-ticketing-www/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-premium-trial-page-index-js": () => import("/buddy/spotify-ticketing-www/src/templates/PremiumTrialPage/index.js" /* webpackChunkName: "component---src-templates-premium-trial-page-index-js" */),
  "component---src-pages-auth-js": () => import("/buddy/spotify-ticketing-www/src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/buddy/spotify-ticketing-www/.cache/data.json")

